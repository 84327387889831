import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  ZoomControl,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { renderToStaticMarkup } from "react-dom/server";
import { FaLocationDot } from "react-icons/fa6";
import PropTypes from "prop-types";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import StrinLocalized from "../../Language/language.lang";
import "leaflet-geosearch/dist/geosearch.css";
import { DisabledByDefault } from "@mui/icons-material";
const GeoSearch = ({ searchQuery }) => {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      style: "bar",
      autoClose: true,
      keepResult: true,
      searchLabel: StrinLocalized.searchLabelMap,
      notFoundMessage: StrinLocalized.notFoundMessageMap,
      marker: false,
    });

    map.addControl(searchControl);

    if (searchQuery) {
      provider.search({ query: searchQuery }).then((result) => {
        if (result && result.length > 0) {
          const { x, y } = result[0]; // گرفتن طول و عرض جغرافیایی اولین نتیجه
          map.setView([y, x], 13); // تنظیم مرکز نقشه
        }
      });
    }

    return () => map.removeControl(searchControl);
  }, [map, searchQuery]);

  return null;
};

// اضافه کردن PropTypes برای کامپوننت GeoSearch
GeoSearch.propTypes = {
  searchQuery: PropTypes.string, // تعریف پراپ به عنوان رشته
};

export default GeoSearch;
