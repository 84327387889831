import { toast } from "react-toastify";
import axiosInstance from "../HelperAxios/axios";
 

export const getAll = async (setList, url) => {
  const response = await axiosInstance
    .get(`${url}`)
    .then((res) => {
      setList(res.data.data);
      return res;
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        location.href = "/login";
      } else if (error.response.status === 403) {
        toast.warning("Dear user, you do not have access to this section");
      } else if (error.response.status === 404) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(error.response?.data.message);
      }
      return error;
    });
  return response;
};
