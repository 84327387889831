import { styled, Box } from "@mui/material";

export const InputRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "15px",
  paddingRight: "19px",
  "@media(max-width: 768px)": {
    flexDirection: "column",
  },
}));

export const FormLabel = styled("span")(() => ({
  width: "133px",
  fontSize: "14px",
  display: "flex",
  paddingLeft: "5px",
  "@media(max-width: 768px)": {
    width: "100%",
    marginBottom: "8px",
  },
}));

export const Fields = styled(Box)(() => ({
  // width: "fit-content",
  width: "100%",
  marginTop: "35px",
}));

export const SubmitContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  margin: "30px 16px",
}));

export const InfoRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "15px",
  paddingRight: "19px",
  flexWrap: "wrap",
}));

export const InfoLabel = styled("span")(() => ({
  width: "150px",
  fontSize: "16px",
  display: "flex",
  "@media(max-width: 768px)": {
    width: "125px",
    fontSize: "14px",
  },
}));
