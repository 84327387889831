import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  ZoomControl,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { renderToStaticMarkup } from "react-dom/server";
import { FaLocationDot } from "react-icons/fa6";
import PropTypes from "prop-types";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import StrinLocalized from "../../Language/language.lang";
import "leaflet-geosearch/dist/geosearch.css";
import { DisabledByDefault } from "@mui/icons-material";
import GeoSearch from "./GeoSearch";

const OpenStreetMap = ({
  onClick,
  defaultAddress,
  searchQuery,
  height = "300px",
}) => {
  const [position, setPosition] = useState(defaultAddress || null);
  const [isFullscreen, setIsFullscreen] = useState(false); // وضعیت تمام صفحه
  const mapRef = useRef();

  const customIcon = L.divIcon({
    html: renderToStaticMarkup(
      <FaLocationDot
        color="#ff0000"
        style={{ width: "30px", height: "30px" }}
      />
    ),
    className: "",
  });

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
        onClick(e.latlng);
      },
    });

    return position === null ? null : (
      <Marker position={position} icon={customIcon} />
    );
  };

  useEffect(() => {
    if (defaultAddress) {
      setPosition(defaultAddress);
    }
  }, [defaultAddress, searchQuery, position]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      mapRef.current.requestFullscreen().catch((err) => console.log(err));
      setIsFullscreen(true); // به روزرسانی وضعیت تمام صفحه
    } else {
      document.exitFullscreen();
      setIsFullscreen(false); // به روزرسانی وضعیت تمام صفحه
    }
  };

  return (
    <div style={{ position: "relative", height: height }} ref={mapRef}>
      <button
        type="button"
        onClick={toggleFullscreen}
        style={{
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 1000,
          padding: "10px",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        {isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
        {/* نمایش متن بر اساس حالت */}
      </button>
      {/* 60.166098,24.9421019 */}
      <MapContainer
        center={position || [60.166098, 24.9421019]}
        zoom={13}
        style={{
          height: "100%",
          width: "100%",
        }}
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* <GeoSearch searchQuery={searchQuery} /> */}

        <ZoomControl position="topright" />

        <LocationMarker />
      </MapContainer>
    </div>
  );
};

OpenStreetMap.propTypes = {
  onClick: PropTypes.func,
  searchQuery: PropTypes.string,
  height: PropTypes.string,
  defaultAddress: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
};

export default OpenStreetMap;
