import { Suspense } from "react";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { CookiesProvider } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/login.page";
import { allRoutes } from "./Routes/routes.route";
import Layout from "./Layout/layout";
import LoginByPhone from "./Pages/Login/forgetPassword";
import RegisterCarOwner from "./Pages/RegisterCarOwner/RegisterCarOwnerLogin.page";
import store from "./Utils/auth/store";
import AuthProvider from "react-auth-kit";

import ForgetPasswordConfirm from "./Pages/Login/confirmForgetPassword.page";
import ShareScreenPage from "./Pages/ShareScreenPage/ShareScreen.page";

function App() {
  return (
    <AuthProvider store={store}>
      <CookiesProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login-by-phone" element={<LoginByPhone />} />
          <Route path="/reset-password" element={<ForgetPasswordConfirm />} />
          <Route path="/add-car-owner-login" element={<RegisterCarOwner />} />
          <Route path="/share-screen" element={<ShareScreenPage />} />
          <Route path="/" element={<Layout />}>
            {allRoutes.map(({ path, component: Component }, index) => (
              <Route
                key={index}
                path={path}
                element={
                  <Suspense fallback={<div>loading...</div>}>
                    <Component />
                  </Suspense>
                }
              />
            ))}
          </Route>
        </Routes>
      </CookiesProvider>
    </AuthProvider>
  );
}

export default App;
