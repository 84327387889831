import { CalenderIconWrapper } from "./calenderStyles/calender.styles";

const Calender = () => {
  return (
    <CalenderIconWrapper>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.7366 17.679H1.08324C1.00695 17.679 0.940633 17.6137 0.940633 17.5373V8.46004H18.8786V17.5373C18.8786 17.6143 18.8164 17.679 18.736 17.679H18.7366ZM1.08324 3.56551H5.03594V5.71272C5.03594 5.9737 5.24777 6.18588 5.51183 6.18588C5.76885 6.18588 5.9807 5.9737 5.9807 5.71272V3.56551H13.8374V5.71272C13.8374 5.9737 14.0492 6.18588 14.3133 6.18588C14.5703 6.18588 14.7827 5.9737 14.7827 5.71272V3.56551H18.7354C18.8153 3.56551 18.8781 3.63077 18.8781 3.70718V7.51372H0.94006V3.70718C0.94006 3.63018 1.00576 3.56551 1.08264 3.56551H1.08324ZM18.7366 2.61977H14.7839V0.473159C14.7839 0.212181 14.5721 0 14.3145 0C14.0504 0 13.8386 0.211593 13.8386 0.473159V2.61977H5.98187V0.473159C5.98187 0.212181 5.77002 0 5.513 0C5.24835 0 5.03711 0.211593 5.03711 0.473159V2.61977H1.08441C0.486459 2.61977 0 3.10765 0 3.70778V17.5367C0 18.1363 0.486459 18.6241 1.08441 18.6241H18.7378C19.3393 18.6241 19.8257 18.1363 19.8257 17.5367V3.70778C19.8257 3.10824 19.3393 2.61977 18.7378 2.61977H18.7372H18.7366Z"
          fill="#ECE8DC"
        />
      </svg>
    </CalenderIconWrapper>
  );
};

export default Calender;
