import React, { useState, useRef } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import TextBox from "../../Inputs/TextBox/textBox.component";
import { CalenderWrapper } from "./datepickerStyles/datepicker.style";
import { showCal } from "./datepickerUtils/datepicker.utils";
import CalenderIcon from "../../../Icons/inputIcons/Calender/calender.icon";
import PropTypes from "prop-types";

const Date = ({ placeHolder, width, height, onCustomSelect, value }) => {
  const [showCalender, setShowCalender] = useState(false);
  const claRef = useRef();
  return (
    <div ref={claRef} style={{ position: "relative" }}>
      <TextBox
        width={width}
        height={height}
        placeHolder={placeHolder}
        onChange={(event) => console.log(event)}
        onClick={showCal(showCalender, setShowCalender)}
        icon={<CalenderIcon />}
        value={
          value?.length > 0
            ? `${value[0].year}/${value[0].month}/${value[0].day}`
            : ""
        }
        autoComplete="off"
      />
      {showCalender && (
        <CalenderWrapper offset={claRef.current.getBoundingClientRect().left}>
          <Calendar
            value={value[0]}
            onChange={(values) => {
              let date = [];
              date[0] = values;
              onCustomSelect(date);
              setShowCalender(false);
            }}
            shouldHighlightWeekends
          />
        </CalenderWrapper>
      )}
    </div>
  );
};

Date.propTypes = {
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  bottom: PropTypes.number,
  onCustomSelect: PropTypes.func,
};

export default Date;
