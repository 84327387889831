import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const CountryCallingCodeSelect = ({ onChange, value, readOnly }) => {
  return (
    <PhoneInput
      className="CountryCallingCodeSelect"
      placeholder="Enter phone number"
      value={value}
      onChange={onChange}
      readOnly={readOnly}
    />
  );
};

// تعریف صحیح PropTypes
CountryCallingCodeSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default CountryCallingCodeSelect;
