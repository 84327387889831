import StrinLocalized from "../../Language/language.lang";
import Button from "../../components/Inputs/Button/button.component";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  FormLabel,
  InputRow,
  Fields,
  SubmitContainer,
} from "./RegisterAgentStyles/registerAgent.style";
import { primaryColor, violet } from "../../colors/allColors.color";
import TextBox from "../../components/Inputs/TextBox/textBox.component";
import StarIcon from "../../Icons/FormsIcon/Star/Star.icon";
import { useForm, Controller } from "react-hook-form";
import UploadInput from "../../components/Inputs/UploadInput/UploadInput.component";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  API_URL,
  API_URL_COMMON,
  API_URL_FLEET,
} from "../../HelperAxios/const";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../HelperAxios/axios";
import SelectBox from "../../components/Inputs/SelectBox/SelectBox.component";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import Date from "../../components/Inputs/DatePicker/datePicker.component";
import CountryCallingCodeSelect from "../../components/Inputs/SelectBox/countryList";

const commonStyles = {
  bordder: 1,
};

const RegisterCarOwner = () => {
  const { handleSubmit, control } = useForm();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [otherDociment, setOtherDocument] = useState([]);
  const [idCardImage, setIdCardImage] = useState([]);
  const [phone, setPhone] = useState("");
  const [showSms, setShowSms] = useState(false);
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState(null);
  const [convertDate, setConvertDate] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(
    `${StrinLocalized.defaultNumber}`
  );
  /*** handle localStorage Setting ***/
  localStorage.removeItem("token-pikgo");
  localStorage.removeItem("tokenR-niko");
  localStorage.removeItem("user-pikgo");
  localStorage.removeItem("role-niko");
  localStorage.removeItem("permissions-niko");
  const signIn = useSignIn();
  /**** upload photo ***/
  const UploadNationalPic = async (data) => {
    const formData = new FormData();
    const index = data.index;
    formData.append("file", data.file);
    formData.append("title", data.file.name);
    formData.append("field_name", "other_Documents");
    toast.info(StrinLocalized.sendData);
    console.log(formData);
    await axios({
      method: "post",
      url: `${API_URL_COMMON}/files/create`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast.success(StrinLocalized.SuccessAction);
        console.log(res.data);
        const uploadedFileUrl = res?.data?.data?.uploadedFile?.url;
        const uploadedFilePath = res?.data?.data?.uploadedFile?.path;
        setUploadedFiles((prevFiles) => [...prevFiles, uploadedFileUrl]);
        setOtherDocument((prevFiles) => [...prevFiles, uploadedFilePath]);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  };

  const UploadNotarizedPicPic = async (data) => {
    const formData = new FormData();
    const index = data.index;
    formData.append("file", data.file);
    formData.append("title", data.file.name);
    formData.append("field_name", "id_card_image");
    toast.info(StrinLocalized.sendData);
    console.log(formData);
    await axios({
      method: "post",
      url: `${API_URL_COMMON}/files/create`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast.success(StrinLocalized.SuccessAction);
        console.log(res.data);
        setIdCardImage(() => {
          return [
            {
              path: res.data.data.uploadedFile.path,
              url: res.data.data.uploadedFile.url,
              index: index,
              field: res.data.data.field_name,
            },
          ];
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  };
  // const SendSms = () => {
  //   toast.info(StrinLocalized.sendData);
  //   axiosInstance
  //     .post(
  //       "https://api.nikohamrah.com/api/v1/portal/auth/user/car-owner/create-send-sms",
  //       { mobile: phone },
  //       {
  //         headers: [{ "Accept-Language": "fa" }],
  //       }
  //     )
  //     .then((res) => {
  //       toast.success(StrinLocalized.SuccessAction);
  //       setShowSms(true);
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };
  /*** submit form ***/
  const onSubmit = (data) => {
    let time = data.birthdate;
    if (data.birthdate) {
      time =
        data?.birthdate[0].year +
        "-" +
        data?.birthdate[0].month +
        "-" +
        data?.birthdate[0].day;
    }
    toast.info(StrinLocalized.sendData);
    if (!data.password || data.password.length < 8) {
      toast.error("Password must be more than 8 characters");
      return;
    }

    // Check if the passwords are exactly the same character by character
    const passwordsMatch =
      data.password.length === data.repeat.length &&
      [...data.password].every((char, index) => char === data.repeat[index]);
    if (passwordsMatch) {
      data = {
        // code: data.validationCode,
        username: data?.username,
        password: data?.password,
        retype_password: data?.repeat,
        first_name: data?.name,
        last_name: data?.surname,
        mobile: phoneNumber,
        email: data?.email,
        id_card_number: data?.personal_code,
        birthday: time,
        company_address: data?.company_address,
        postal_code: data?.postal_code,
        company_register_number: data?.company_register_number,
        id_card_image: idCardImage[0]?.path,
        other_documents: uploadedFiles,
      };
      console.log(data);
      axios
        .post(`${API_URL_FLEET}/auth/register`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
          // const data = res.data.data;
          // if (data) {
          //   localStorage.setItem("token-pikgo", data.access_token.token);
          //   // localStorage.setItem("token-pikgo", JSON.stringify(access_token));
          //   localStorage.setItem(
          //     "token-pikgo",
          //     JSON.stringify(data.access_token.token)
          //   );
          //   setTimeout(() => {
          //     navigate("/dashboard/admin");
          //   }, 2000);
          //   localStorage.setItem("user-pikgo", JSON.stringify(data.user));
          // } else {
          //   toast.error("Something went wrong");
          // }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
          if (err?.response?.data?.errors) {
            err?.response?.data?.errors.map((e, i) => {
              toast.error(e);
            });
          }
        });
      //   axios
      //     .post(`${API_URL_FLEET}"/auth/register`, data, {
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //     })
      //     .then((res) => {
      //       // toast.success(StrinLocalized.SuccessAction);
      //       toast.success(res?.data?.message);

      //       const data = res?.data?.data;

      //       if (data && data.access_token) {
      //         localStorage.setItem("token-pikgo", data.access_token);
      //         localStorage.setItem("user-pikgo", JSON.stringify(data.fleet));
      //         // if (
      //         //   signIn({
      //         //     auth: { token: data.access_token, type: "Bearer" },
      //         //     userState: data.user,
      //         //     refresh_token: data.access_token,
      //         //   })
      //         // )
      //         // {
      //         setTimeout(() => {
      //           if (
      //             data.user.role === "صاحب ماشین" ||
      //             data.user.role === "نماینده ماشین"
      //           ) {
      //             navigate("/dashboard/carowner");
      //           } else if (data.user.role === "راننده خودرو") {
      //             navigate("/dashboard/driver");
      //           } else if (data.user.role === "نماینده شرکت") {
      //             navigate("/dashboard/company");
      //           } else {
      //             navigate("/dashboard/admin");
      //           }
      //         }, 2500);
      //         // }
      //         // else {
      //         //   toast.error("Something went wrong");
      //         // }
      //         // localStorage.setItem("user-pikgo", JSON.stringify(data?.fleet));
      //         // localStorage.setItem("role-niko", btoa(data?.roles));
      //         // localStorage.setItem("permissions-niko", btoa(data?.permissions));
      //       }
      //       toast.success(StrinLocalized.SuccessAction);
      //       console.log(res);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       toast.error(err?.message || "An error occurred");
      //     });
      //   // axiosInstance
      //   //   .post(`${API_URL_FLEET}/auth/register`, data)
      //   //   .then(() => {
      //   //     toast.success(StrinLocalized.SuccessAction);
      //   //     setTimeout(() => {
      //   //       navigate("/login");
      //   //     }, 2000);
      //   //   })
      //   //   .catch((err) => {
      //   //     toast.error(err.response.data.message);
      //   //   });
    } else {
      toast.error("Password don't match with password Again");
    }
  };

  return (
    <div>
      <div
        style={{
          background: violet,
          width: "100%",
          height: "70px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          fontSize: "16px",
          fontWeight: "700",
        }}
      >
        <span>{StrinLocalized.SignUpCarOwner}</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fields>
          <InputRow>
            <FormLabel>
              {StrinLocalized.UserName}
              <StarIcon />
            </FormLabel>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Name}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                  rounded
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>
              {StrinLocalized.Password}
              <StarIcon />
            </FormLabel>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  type={"password"}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Password}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>
              {StrinLocalized.Repeat}
              <StarIcon />
            </FormLabel>
            <Controller
              name="repeat"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  type={"password"}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Repeat}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>
              {StrinLocalized.Name}
              <StarIcon />
            </FormLabel>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Name}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>
              {StrinLocalized.SurName}
              <StarIcon />
            </FormLabel>
            <Controller
              name="surname"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.SurName}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>
              {StrinLocalized.Phone}
              <StarIcon />
            </FormLabel>

            {/* <Controller
              name="mobile"
              control={control}
              // rules={{ required: true }}
              render={({
                field: { onChange, value = `${StrinLocalized.defaultNumber}` },
              }) => ( */}
            <CountryCallingCodeSelect
              onChange={(e) => {
                setPhoneNumber(e);
              }}
              value={phoneNumber}
            />
            {/* )}
            /> */}
          </InputRow>
          <InputRow>
            <FormLabel>
              {StrinLocalized.Email}
              <StarIcon />
            </FormLabel>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Email}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>
              {StrinLocalized.PersonalCode}
              <StarIcon />
            </FormLabel>
            <Controller
              name="personal_code"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.PersonalCode}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>

          <InputRow>
            <FormLabel>{StrinLocalized.BirthDate}</FormLabel>
            <Controller
              name="birthdate"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <Date
                  placeHolder={StrinLocalized.BirthDate}
                  onCustomSelect={onChange}
                  value={value}
                  width={571}
                  height={38}
                />
              )}
            />
          </InputRow>

          <div className="w-full mt-32"></div>
          <InputRow>
            <FormLabel>{StrinLocalized.CompanyAddress}</FormLabel>
            <Controller
              name="company_address"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  // required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.CompanyAddress}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>{StrinLocalized.PostalCode}</FormLabel>
            <Controller
              name="postal_code"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  // required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.PostalCode}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <FormLabel>{StrinLocalized.CompanyRegisterNumber}</FormLabel>
            <Controller
              name="company_register_number"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  // required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.CompanyRegisterNumber}
                  width={571}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputRow>
          <InputRow>
            <InputRow>
              <FormLabel>
                {StrinLocalized.PersonalCardPicture}
                <StarIcon />
              </FormLabel>
              <UploadInput
                placeHolder={StrinLocalized.PersonalCardPicture}
                width={480}
                height={38}
                index={idCardImage.index ? idCardImage.index : 0}
                uploadValue={UploadNotarizedPicPic}
                sx={{ ...commonStyles, borderRadius: 1 }}
              />
            </InputRow>
            <InputRow>
              {idCardImage[0]?.url ? (
                <button
                  type="button"
                  className="border p-2 bg-[#DaD4C4]"
                  onClick={() =>
                    window.open(
                      `https://${
                        idCardImage[0]?.url ? idCardImage[0]?.url : ""
                      }`,
                      "_blank"
                    )
                  }
                >
                  {`View`}
                </button>
              ) : (
                ""
              )}
            </InputRow>
          </InputRow>

          {/* <InputRow>
            <FormLabel>{StrinLocalized.OtherDocuments}</FormLabel>

            <UploadInput
              placeHolder={StrinLocalized.OtherDocuments}
              width={480}
              height={38}
              index={uploadedFiles.index ? uploadedFiles.index : 0}
              uploadValue={UploadNationalPic}
              sx={{ ...commonStyles, borderRadius: 1 }}
            />
          </InputRow> */}
          <InputRow>
            <InputRow>
              <FormLabel>{StrinLocalized.PhotoDocuments}</FormLabel>
              <Controller
                name="document_url_2"
                control={control}
                // rules={{ required: true }}
                render={({ field: { onChange, value = "" } }) => (
                  <UploadInput
                    placeHolder={StrinLocalized.PhotoDocuments}
                    sx={{ ...commonStyles, borderRadius: 1 }}
                    width={480}
                    height={38}
                    onChange={onChange}
                    value={value}
                    index={uploadedFiles.index ? uploadedFiles.index : 0}
                    uploadValue={UploadNationalPic}
                  />
                )}
              />
            </InputRow>
            <InputRow>
              {uploadedFiles.map((url, index) => (
                <button
                  type="button"
                  className="border p-2 bg-[#DaD4C4]"
                  key={index}
                  onClick={() => window.open(`https://${url}`, "_blank")}
                >
                  {`View${index + 1}`}
                </button>
              ))}
            </InputRow>
          </InputRow>

          <SubmitContainer>
            <Button
              sx={{ ...commonStyles, borderRadius: "16px" }}
              textcolor="white"
              buttonText={StrinLocalized.Submit}
              type="submit"
              background={violet}
              width={200}
            />
          </SubmitContainer>
        </Fields>
      </form>
      <ToastContainer rtl={true} />
    </div>
  );
};
export default RegisterCarOwner;
