import { styled, Box, List } from "@mui/material";
import { textColor, grayLighter } from "../../../colors/allColors.color";

export const SideBarWrapper = styled(Box)(() => ({
  backgroundColor: grayLighter,
  boxShadow: "-1px 7px 13px -4px rgba(0,0,0,0.25)",
  color: textColor,
  overflowY: "auto",
  "@media(max-width: 1250px)": {
    overflowY: "none",
    height: `${window.innerHeight - 170}px`,
    // position: "absolute",
    // left: "0",
    // zIndex: 99,
    // height: "50vh",
  },
}));

export const SideBarList = styled(List)(() => ({
  width: "auto",
}));
