import React, { useState } from "react";
import TextBox from "../../components/Inputs/TextBox/textBox.component";
import PersonIcon from "../../Icons/FormsIcon/Person/person.icon";
import Button from "../../components/Inputs/Button/button.component";
import { violet } from "../../colors/allColors.color";
import { MdOutlineMail } from "react-icons/md";
import { useMediaQuery } from "@mui/material";
import {
  LoginContainer,
  RightSide,
  FormWrapper,
  FieldWrapper,
  FieldSMS,
  ForgottenText,
  LeftSide,
  LoginText,
  LoginTextContainer,
} from "./loginStyles/login.style";
import { useNavigate } from "react-router-dom";
import LoginImage from "../../assets/loginPic.jpg";
import ReactInputVerificationCode from "react-input-verification-code";
import Countdown from "react-countdown";
import StrinLocalized from "../../Language/language.lang";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL_AUTH, API_URL_PORTAL } from "../../HelperAxios/const";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import axiosInstance from "../../HelperAxios/axios";

const firebaseConfig = {
  apiKey: "AIzaSyDLObR0mApCIc_7eACbA-bj3uBTc_FbCEI",
  authDomain: "nikohamrah-fcm.firebaseapp.com",
  projectId: "nikohamrah-fcm",
  storageBucket: "nikohamrah-fcm.appspot.com",
  messagingSenderId: "767909872243",
  appId: "1:767909872243:web:c6162785219a7d2d2ee006",
};

const LoginByPhone = () => {
  const Navigate = useNavigate();
  const showLeftBox = useMediaQuery("(min-width:600px)");
  const [email, setEmail] = useState("");
  // const [code, setCode] = useState("");
  const [sms, setSms] = useState(false);
  const [smsAgain, setSmsAgain] = useState(false);
  const [time, setTime] = useState(false);
  const navigate = useNavigate();

  /*** handle localStorage Setting ***/
  // localStorage.removeItem("token-pikgo");
  // localStorage.removeItem("tokenR-niko");
  // localStorage.removeItem("user-pikgo");
  // localStorage.removeItem("role-niko");
  // localStorage.removeItem("permissions-niko");

  // const Completionist = () => <span>You are good to go!</span>;
  // const renderer = ({ minutes, seconds, completed }) => {
  //   if (completed) {
  //     setSmsAgain(true);
  //     setSms(false);
  //     return <Completionist />;
  //   } else {
  //     // Render a countdown
  //     return (
  //       <span>
  //         {minutes}:{seconds}
  //       </span>
  //     );
  //   }
  // };
  // let code;
  const handlePhone = (data) => {
    setEmail(data);
  };
  // const handleSms = () => {
  //   const data = { mobile: email };
  //   toast.info(StrinLocalized.sendData);
  //   console.log(data);
  // axios
  //   .post(API_URL_AUTH + "/login/send-code", data, {
  //     headers: { "accept-language": "fa" },
  //   })
  //   .then(() => {
  //     setTime(!time);
  //     setSms(true);
  //     toast.success("پیامک ورود با موفقیت ارسال شد .");
  //   })
  //   .catch((err) => {
  //     toast.error(err.response.data.message);
  //   });
  // };
  const onSubmit = () => {
    const data = { email: email };
    toast.info(StrinLocalized.sendData);
    console.log(data);
    axios
      .post(`${API_URL_PORTAL}/auth/reset-password`, data, {
        headers: { "accept-language": "fa" },
      })
      .then((res) => {
        console.log(res);
        if (res?.status == 200 || res?.status == 201) {
          toast.success(res.data.message);
          setTimeout(() => navigate("/login"), 2500);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data.message);
      });
    // axios
    //   .post(API_URL_AUTH + "/login/verify-code", data, {
    //     headers: { "accept-language": "fa" },
    //   })
    //   .then((res) => {
    //     let data = res.data.data;
    //     console.log(data);
    //     if (data) {
    //       localStorage.setItem("token-pikgo", JSON.stringify(data.token.token));
    //       localStorage.setItem(
    //         "tokenR-niko",
    //         JSON.stringify(data.token.refreshToken)
    //       );
    //       localStorage.setItem("user-pikgo", JSON.stringify(data.user));
    //       // localStorage.setItem("role-niko", btoa(data.roles));
    //       // localStorage.setItem("permissions-niko", btoa(data.permissions));
    //     }
    //     toast.success(StrinLocalized.SuccessLogin);
    //     setTimeout(() => {
    //       const app = initializeApp(firebaseConfig);
    //       const messaging = getMessaging(app);
    //       console.log(messaging);
    //       console.log(app);
    //       /**** update fcm ****/
    //       axiosInstance
    //         .put("user/update-fcm-token", {
    //           fcmToken: "AIzaSyDLObR0mApCIc_7eACbA-bj3uBTc_FbCEI",
    //         })
    //         .then(() => {
    //           if (data.role === "صاحب ماشین" || data.role === "نماینده ماشین") {
    //             navigate("/dashboard/carowner");
    //           } else if (data.role === "راننده خودرو") {
    //             navigate("/dashboard/driver");
    //           } else if (data.role === "نماینده شرکت") {
    //             navigate("/dashboard/company");
    //           } else {
    //             navigate("/dashboard/admin");
    //           }
    //           // location.reload();
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     }, 2000);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data.message);
    //   });
  };
  return (
    <div>
      <LoginContainer>
        {showLeftBox && (
          <LeftSide backgroundPhoto={LoginImage}>
            <LoginTextContainer>
              <LoginText> </LoginText>
              <LoginText> </LoginText>
            </LoginTextContainer>
          </LeftSide>
        )}
        <RightSide>
          <FormWrapper>
            <form>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h1>{StrinLocalized.LoginLabel}</h1>
              </div>
              <FieldWrapper>
                <TextBox
                  id="email"
                  name="email"
                  placeHolder={StrinLocalized.SubmitLoginByEmail}
                  width={450}
                  height={45}
                  centerText
                  rounded
                  icon={<MdOutlineMail />}
                  onChange={(e) => handlePhone(e.target.value)}
                />
              </FieldWrapper>
              {/* <FieldWrapper>
                {!smsAgain && !time ? (
                  <Button
                    buttonText={StrinLocalized.sendCode}
                    width={450}
                    height={45}
                    background={violet}
                    textcolor="#FFFFFF"
                    rounded
                    click={handleSms}
                  />
                ) : (
                  <></>
                )} */}

              {/* {time && smsAgain ? (
                  <Button
                    buttonText={StrinLocalized.SendSmsOTPRepeat}
                    width={450}
                    height={45}
                    background={violet}
                    textcolor="#FFFFFF"
                    rounded
                    click={handleSms}
                  />
                ) : (
                  <></>
                )}
              </FieldWrapper>

              {sms ? (
                <div
                  style={{
                    fontSize: "32px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "1rem 0",
                  }}
                >
                  <Countdown date={Date.now() + 120000} renderer={renderer} />
                </div>
              ) : (
                <></>
              )} */}
              {/* {sms ? (
                <FieldSMS>
                  <ReactInputVerificationCode
                    autoFocus
                    placeholder=""
                    length={4}
                    onChange={(e) => handleCode(e)}
                  />
                </FieldSMS>
              ) : (
                <></>
              )}
              {sms && (
                <FieldWrapper>
                  <Button
                    buttonText={StrinLocalized.SubmitLogin}
                    width={450}
                    height={45}
                    background={violet}
                    textcolor="#FFFFFF"
                    rounded
                    click={onSubmit}
                  />
                </FieldWrapper>
              )} */}
              <FieldWrapper>
                <Button
                  buttonText={StrinLocalized.SendEmail}
                  width={450}
                  height={45}
                  background={violet}
                  type={"button"}
                  textcolor="#FFFFFF"
                  rounded
                  click={onSubmit}
                />
              </FieldWrapper>

              {/* <FieldWrapper>
                <ForgottenText
                  onClick={() => {
                    Navigate("/add-car-owner-login");
                  }}
                >
                  {StrinLocalized.RegisterCarOwner2}
                </ForgottenText>
              </FieldWrapper> */}
            </form>
          </FormWrapper>
        </RightSide>
      </LoginContainer>
      <ToastContainer /> {/*** rtl={true}***/}
    </div>
  );
};

export default LoginByPhone;
