import { useEffect, useState } from "react";
import { HeaderWrapper, HeaderContainer } from "./headerStyles/header.style";
// import Logo from "../Logo/logo.component";
import StatusHeader from "./statusHeader.component";
import { getCommon } from "../../Utils/getCommon";
import * as propTypes from "prop-types";

const Header = () => {
  const [headerColor, setHeaderColor] = useState("#1337a9");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    getCommon((data) => {
      console.log("from header ====> ", data);
      setLogo(data?.template?.logo);
      setHeaderColor(data?.template?.color_header || "#1337a9");
    }, "/settings/read");
  }, []);

  return (
    <HeaderWrapper headerColor={headerColor}>
      <HeaderContainer>
        {/* right side header */}
        <StatusHeader />
        {logo && <img src={`https://${logo}`} width="50px" />}
        {/* left side header */}
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
