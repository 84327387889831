import { FooterWrapper } from "./footerStyles/footer.style";
import StrinLocalized from "../../Language/language.lang";
import LogoAkaf from "../../assets/footerLogo.png";

const Footer = () => {
  return (
    <FooterWrapper>
      {/* {StrinLocalized.FooterText} */}
      Designed and developed by
      <a className="text-blue-500 ml-1" href="http://akafweb.com/en/">
        Akaf Company
      </a>
      .© All rights reserved by Pikpalvelu Company
      <a className="text-blue-500 ml-1" href="http://akafweb.com/en/">
        <img src={LogoAkaf} alt="akaf-logo" />
      </a>
    </FooterWrapper>
  );
};

export default Footer;
