import { API_URL_PORTAL_USER } from "./const";
import axios from "axios";

const baseURL = API_URL_PORTAL_USER;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(async function (config) {
  const token = JSON.parse(localStorage.getItem("token-pikgo"));
  config.headers.Authorization = `Bearer ${token}`;
  config.headers["accept-language"] = "en";
  config.headers["Cache-Control"] = "no-cache";
  config.headers["Pragma"] = "no-cache";
  return config;
});

export default axiosInstance;
