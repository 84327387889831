import { Box } from "@mui/material";
import React, { useEffect } from "react";
import OpenStreetMap from "../../components/OpenStreetMap/OpenStreetMap.component";
import CheckPermission from "../../Utils/checkPermission";
import { useNavigate } from "react-router";

const ShareScreenPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // if (!CheckPermission("shared_trip", "list")) {
    //   navigate(-1);
    // }
    // getAll(setUser, "/profile/get");
  }, []);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <OpenStreetMap height="100vh" />
    </Box>
  );
};

export default ShareScreenPage;
