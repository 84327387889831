import React, { useCallback, useEffect, useState } from "react";
import TextBox from "../../components/Inputs/TextBox/textBox.component";
import PersonIcon from "../../Icons/FormsIcon/Person/person.icon";
import EyeIcon from "../../Icons/FormsIcon/Eye/eye.icon";
import Button from "../../components/Inputs/Button/button.component";
import { primaryColor, violet } from "../../colors/allColors.color";
import { useMediaQuery } from "@mui/material";
import { LoginContainer, RightSide, FormWrapper, FieldWrapper, ForgottenText, LeftSide, LoginText, LoginTextContainer, FleetsRegister } from "./loginStyles/login.style";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import LoginImage from "../../assets/loginPic.jpg";
import StrinLocalized from "../../Language/language.lang";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_URL_PORTAL } from "../../HelperAxios/const";
import { getCommon } from "../../Utils/getCommon";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useFCM } from "../../fcm/useFcm";

const Login = () => {
  const Navigate = useNavigate();
  const showLeftBox = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const signIn = useSignIn();

  const toggleVisibleHandler = useCallback(() => {
    setVisible(() => !visible);
  }, [visible]);
  const { loadToken } = useFCM();

  useEffect(() => {
    /*** handle localStorage Setting ***/
    localStorage.removeItem("token-pikgo");
    localStorage.removeItem("tokenR-niko");
    localStorage.removeItem("user-pikgo");
    localStorage.removeItem("pikgo-perm");
    localStorage.removeItem("permissions-niko");

    // Alret to Enable push notifications
    try {
      loadToken();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const { handleSubmit, control } = useForm();
  const [setting, setSetting] = useState();
  useEffect(() => {
    getCommon(setSetting, "settings/read");
    console.log("setting ====> ", setting);
  }, []);

  //
  const onSubmit = async (data) => {
    toast.info(StrinLocalized.sendData);
    try {
      const { data: resData, status } = await axios.post(API_URL_PORTAL + "/auth/login", data);

      if (status === 200) {
        toast.success(StrinLocalized.SuccessLogin);

        let { data } = resData;
        if (data) {
          localStorage.setItem("token-pikgo", data.token.token);
          localStorage.setItem("token-pikgo", JSON.stringify(data.token.token));
          if (
            signIn({
              auth: { token: data.token.token, type: "Bearer" },
              userState: data.user,
              refresh_token: data.token.token,
            })
          ) {
          }
          localStorage.setItem("user-pikgo", JSON.stringify(data.user));
        }
        navigate("/dashboard/admin");
      }
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  return (
    <div>
      <LoginContainer>
        {showLeftBox && (
          <LeftSide backgroundPhoto={setting?.portalLoginPage?.photoUrl ? `https://${setting?.portalLoginPage?.photoUrl}` : LoginImage}>
            <LoginTextContainer>
              <LoginText>{setting?.portalLoginPage?.title ? setting.portalLoginPage.title : ""}</LoginText>
              <LoginText>{setting?.portalLoginPage?.text ? setting.portalLoginPage.text : ""}</LoginText>
            </LoginTextContainer>
          </LeftSide>
        )}
        <RightSide>
          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={`https://${setting?.template?.logo}`} alt="logo" width={110} />
              </div>
              <FieldWrapper>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextBox id="username" name="username" placeHolder={StrinLocalized.UserName} width={450} height={45} centerText rounded icon={<PersonIcon />} onChange={onChange} value={value} />
                  )}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextBox
                      id="password"
                      name="password"
                      placeHolder={StrinLocalized.Password}
                      width={450}
                      height={45}
                      rounded
                      icon={<EyeIcon onClick={toggleVisibleHandler} />}
                      centerText
                      type={visible ? "password" : "show"}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Button buttonText={StrinLocalized.SubmitLogin} width={450} height={45} background={violet} textcolor="#FFFFFF" rounded type={"submit"} />
              </FieldWrapper>

              <FieldWrapper>
                <FleetsRegister
                  onClick={() => {
                    Navigate("/add-car-owner-login");
                  }}
                >
                  {StrinLocalized.FleetsRegister}
                </FleetsRegister>
                <ForgottenText
                  onClick={() => {
                    Navigate("/login-by-phone");
                  }}
                >
                  {StrinLocalized.ForgetPassword}
                </ForgottenText>
              </FieldWrapper>
            </form>
          </FormWrapper>
        </RightSide>
      </LoginContainer>
      <ToastContainer rtl={true} />
    </div>
  );
};

export default Login;
