import Bell from "../../Icons/headerIcon/bell/bell.icon";
import { StatusProfWrapper, UserAvatar } from "./headerStyles/statusHeader.style";
import LogoutIcon from "@mui/icons-material/Logout";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAll } from "../../Utils/getAll";
import axios from "axios";
import { API_URL_PORTAL_USER } from "../../HelperAxios/const";
// import FakeProf from "../../assets/fakeProf.png";

const statusHeader = () => {
  const Navigate = useNavigate();
  // const user = JSON.parse(localStorage.getItem("user-pikgo"));
  const [user, setUser] = useState([]);

 

  useEffect(() => {
    getAll(setUser, "/profile/get");
  }, []);
  const token = JSON.parse(localStorage.getItem("token-pikgo"));
  const exit = () => {
    axios
      .post(
        `${API_URL_PORTAL_USER}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return (
    <StatusProfWrapper color="red">
      <div style={{ cursor: "pointer", transform: "rotate(180deg)" }} onClick={() => exit()}>
        <LogoutIcon />
      </div>
      <Bell sx={{ cursor: "pointer" }} onClick={() => Navigate("notification-list")} />
      <Typography style={{ fontSize: "12px" }} variant="subtitle2">
        {user?.name ? user.name + " " + user.lname : ""}
      </Typography>
      <UserAvatar onClick={() => Navigate("my-profile")} style={{ cursor: "pointer" }} alt="niko-profile-avatar" src={user?.photo_url ? `https://${user.photo_url}` : ""} />
    </StatusProfWrapper>
  );
};

export default statusHeader;
