import MainMenuIcon from "../../Icons/sidebarIcons/mainMenu/mainMenu.icon";
import { SideBarWrapper, SideBarList } from "./sidebarStyles/sideBar.style";
import SideBarData from "./sidebarData/sideBar.data";
import ListItem from "./listItem.component";
import proptypes from "prop-types";
import { useEffect, useLayoutEffect, useState } from "react";
import { getAll } from "../../Utils/getAll";
import CheckPermission from "../../Utils/checkPermission";
import checkPermission from "../../Utils/checkPermission";

export default function NestedList({ subMenuColor, menuColor, widthSidebar }) {
  const token = localStorage.getItem("token-pikgo");
  const user = JSON.parse(localStorage.getItem("user-pikgo"));
  const [permissions, setPermissions] = useState([]);
  const [sideBar, setSideBar] = useState(SideBarData);
  const [isSidebarUpdated, setIsSidebarUpdated] = useState(false); // مدیریت تغییرات سایدبار

  useLayoutEffect(() => {
    getAll(setPermissions, `/permissions/my`);
  }, []);

  if (permissions.permissions?.length > 0) {
    localStorage.setItem("pikgo-perm", JSON.stringify(permissions.permissions));
    sideBar.map((item) => {
      if (item.child) {
        if (item.parent) {
          if (CheckPermission(item.role, item.accessRole, permissions.permissions)) {
            Object.keys(item.child).map((key) => {
              if (!CheckPermission(item.child[key].role, item.child[key].accessRole, permissions.permissions)) {
                delete item.child[key];
              }
            });
            return item;
          } else {
            Object.keys(item.child).map((key) => {
              delete item.child[key];
            });
          }
        } else {
          Object.keys(item.child).map((key) => {
            if (!CheckPermission(item.child[key].role, item.child[key].accessRole, permissions.permissions)) {
              delete item.child[key];
            }
          });
          return item;
        }
      } else {
        if (checkPermission(item.role, item.accessRole, permissions.permissions)) {
          item.single = true;
        }
      }
      return item;
    });
  }

  const filterSideBar = (e) => {
    const filterText = e.target.value.trim().toLowerCase();
    const final = [];

    SideBarData.forEach((item) => {
      if (item.child?.length) {
        const childFilter = item.child.filter((childItem) => childItem.title.toLowerCase().includes(filterText));

        if (childFilter.length) {
          final.push({ ...item, child: childFilter });
        }
      } else if (item.title.toLowerCase().includes(filterText)) {
        final.push(item);
      }
    });

    setSideBar(final.length > 0 ? final : []);
  };

  return token ? (
    <SideBarWrapper
      style={
        widthSidebar === "f1"
          ? {
              transform: "translateX(0)",
              transition: "all 1s",
              display: "block",
            }
          : {
              transform: "translateX(275px)",
              transition: "all 1s",
              display: "none",
            }
      }
      sx={{ bgcolor: menuColor }}
      width={250}
    >
      <SideBarList component="nav" aria-labelledby="nested-list-subheader" subheader={<ListItem title={widthSidebar === "f1" ? "Main Menu" : ""} icon={<MainMenuIcon />} headerListItem />}>
        <input
          type="text"
          placeholder="search"
          onChange={filterSideBar}
          style={{
            width: "100%",
            height: "30px",
          }}
        />
        {sideBar.map((sideBarItem, index) =>
          sideBarItem.child?.filter((child) => child !== null).length > 0 || sideBarItem.single ? (
            <ListItem
              menuColor={menuColor}
              subMenuColor={subMenuColor}
              key={index}
              title={widthSidebar === "f1" ? sideBarItem.title : ""}
              icon={sideBarItem.icon}
              child={sideBarItem.child}
              parentpath={sideBarItem.path}
              openMenu={sideBarItem.openMenu}
            />
          ) : null
        )}
      </SideBarList>
    </SideBarWrapper>
  ) : null;
}

NestedList.propTypes = {
  menuColor: proptypes.string,
  subMenuColor: proptypes.string,
  widthSidebar: proptypes.bool,
};
