import { TextField, styled } from "@mui/material";
import { InputsBorderColor } from "../../../../colors/allColors.color";

/**
 * @param {number} width
 */
export const InputText = styled(TextField)(({ width, height, rounded }) => ({
  border: `1px solid ${InputsBorderColor}`,
  "& fieldset": {
    border: "none",
  },
  width: `${width}px`,
  marginLeft: "8px",
  padding: "0",
  height: height ? `${height}px` : "40px",
  marginBottom: "8px",
  borderRadius: rounded ? "20px" : "none",
  "@media(max-width: 768px)": {
    width: "100%",
  },
  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
    {
      "-webkit-appearance": "none",
      margin: 0,
    },
  "& input[type=number]": {
    "-moz-appearance": "textfield", // برای فایرفاکس
  },
}));
